
import { utagViewMixin } from "~/mixins";

const EVENT_LABEL = "banner";

export default {
  mixins: [utagViewMixin],

  props: { col: { type: Object, default: () => {} } },

  methods: {
    createClassName(title) {
      if (title) {
        return title.toLowerCase().replace(/\s:?/g, "-");
      }
      return "";
    },
    createClassNames({ link_title: linkTitle, sub_title: subTitle }, index) {
      let classStr = `${this.createClassName(linkTitle)} ${this.createClassName(
        subTitle
      )}`;
      if (index > 1) {
        classStr += " mt-10 md:mt-8";
      }
      return classStr;
    },
    getLastLeafColor(linkTxt, index, leavesCount) {
      if (
        linkTxt &&
        linkTxt.toLowerCase().includes("all") &&
        index == leavesCount - 1
      ) {
        return "text-black font-medium";
      }
      return "text-gray-6C6";
    },
    gtmHandler(eventLabel) {
      this.$gtm.push({
        event: "gaEvent",
        eventCategory: "Main Navigation",
        eventAction: undefined,
        eventLabel,
      });
    },
    handleClick(eventLabel) {
      if (eventLabel === "Clearance") {
        this.utagLinkClick({ event_label: EVENT_LABEL });
      }

      this.closeMegaMenu();
      this.gtmHandler(eventLabel);
    },
    closeMegaMenu() {
      this.$store.dispatch("app/setActiveMenuIndex", -1);
    },
  },
};
